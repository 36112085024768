import {graphql} from 'gatsby'
import React from 'react'
import Layout from '../components/layout'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import Link from 'gatsby-link'
import Img from 'gatsby-image'
import BodyClassName from 'react-body-classname';
import PageTransition from 'gatsby-v2-plugin-page-transitions'
import "animate.css/animate.css";
import ScrollAnimation from 'react-animate-on-scroll';
import '../layouts/procore.styl';
import Penrod from '../img/penrod.jpg';
import Moll from '../img/moll.jpg';

import procoreLogo from '../img/procore-white.svg'
import ResumeIcon from '../img/procore-resume-icon.svg'
import Ancestry1 from '../img/ancestry1.png'
import Ancestry2 from '../img/ancestry2.png'
import Ancestry3 from '../img/ancestry3.png'
import Ancestry4 from '../img/ancestry4.png'

export default class ProCorePage extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.articles
    const { edges: trips } = data.trips


    return (
      <Layout>
      <PageTransition>
      <BodyClassName className="procore">
      <div className="page">
      <Helmet>
        <link href="https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700" rel="stylesheet"/>
      </Helmet>
      <article>

      <div style={{"backgroundColor":"#000","backgroundImage":"url(/img/concrete-texture.jpg)","minHeight":"50vh"}}>
      <section className="contentSection" >
        <ScrollAnimation animateIn="fadeInUp2" duration={.5}>
        <header>
          <h1 className="mt-100" style={{"color":"#fff"}}>John +&nbsp;<img alt="" src={procoreLogo} style={{"width":"320px","verticalAlign":"middle","marginTop":"-3px"}}/>
        </h1>
        <p className="introText" ><span style={{"color":"#fff","font-weight":"400"}}>Hello Procore! I’m John Dilworth, and I would love to be a part of Procore as your Vice President of User Experience.</span>
        </p>
        </header>
        </ScrollAnimation>
        </section>
        </div>

        <ScrollAnimation animateIn="fadeInUp2" duration={.5}>
        <section className="contentSection mt-60">
          <div className="row">
            <div className="col-xs-12 col-sm-7">

              <p className="introText noTopMargin">
              I have grown and managed high performing user experience teams.
              </p>
              <p className="introText">I have experience working in-house with large agile teams helping advocate a user-centered approach to software development.</p>

              <p className="introText">I have experience improving and moving forward legacy software.</p>

              <p className="introText">I work hard, love helping others do their best, and would like to bring my talent and experience to Procore.</p>

              <p><Link to="/resume" className="" ><img alt="" src={ResumeIcon} style={{"width":".75em", "verticalAlign":"middle","marginRight":".25em","fill":"#F47E42"}}/> <strong>View my resume</strong></Link></p>
              </div>

              <div className="col-xs-12 col-sm-5">
              <ScrollAnimation animateIn="fadeInRight" duration={1}>
              <h3>Why I’m a great fit</h3>
              <ul>
              <li>20+ years experience</li>
              <li>Nine years of experience in management roles with 20+ employees</li>
              <li>I know how to hire great talent</li>
              <li>Deep understanding of design process and software and mobile development</li>
              <li>I have experience in all areas of user experience including visual design, prototyping and research</li>
              <li>I want to work with great people designing software that makes a difference</li>

              </ul>
              </ScrollAnimation>
            </div>
          </div>
          </section>
          </ScrollAnimation>

          <section className="testimonial mt-100">
            <div className="testimonial-wrapper">

            <ScrollAnimation animateIn="fadeIn" duration={.5}>

            <div className="testimonial">
            <p>“You rarely find this much talent and expertise in a single individual. John is exceptional at strategizing, concepting, articulating, managing, and executing top-notch user experience design. He's a walking wiki on the theory of design and user experience. He collaborates really well, he's affable and friendly to work with, and somehow he manages to do all this while expecting nothing short of great design from those around him. He inspires team members and peers to deliver high-quality product experiences.</p>
            <p>In short, John is pretty fantastic at what he does”</p>
            </div>

            <div className="testimonial-caption" style={{"verticalAlign":"middle"}}>
              <div className="avatar"><img alt="" src={Moll}/></div>
              <p className="testimonial-caption"><strong>Cameron Moll</strong>
              <br/>Author, Speaker<br/> Design Leader at Facebook</p>
            </div>

          </ScrollAnimation>
          </div>
          </section>

          <section className="contentSection mt-60">
            <div style={{"maxWidth":"1024px","margin":"40px auto" }}>
              <div className="row">
                <div className="col-xs-12 col-sm-9">
                  <h1 className="">Case Studies</h1>
                  <p className="">I’ve hand-picked a few case studies to help give you an idea of the work that I’ve accomplished over my years at my current job. In addition to directly working on product interfaces and concepts, I’ve been heavily involved in developing company strategy, hiring, and improving our product design discipline.</p>
                </div>
              </div>
              <div className="row p-20 mt-60">
                <div className="col-md-8 col-sm-8 col-xs-12 bottom-xs">
                  <Link to="/casestudy/ancestry-interfaces/" className="" >
                    <h2 className="heading"><span className="numbers">1</span>Evolving Ancestry Towards Consistency</h2>
                    <p>Over my years at Ancestry, I’ve been able to develop and evolve the interface, incorporating Ancestry’s new & popular DNA product and it’s long-standing family tree product into a unified design. I’ve also radically transformed the way many of the primary interfaces function, allowing users to build their family trees more efficiently.
                    </p>
                    <p><img alt="" src={ResumeIcon} style={{"width":".75em", "verticalAlign":"middle","marginRight":".25em"}}/> <strong>Read This Case Study</strong></p>
                  </Link>
                </div>
                <div className="col-md-4 col-sm-4 col-xs-12 p-20 ">
                  <ScrollAnimation animateIn="fadeInRight" duration={1}>
                    <img alt="" src={Ancestry1} style={{"objectFit":"cover","width":"100%"}}/>
                  </ScrollAnimation>
                </div>
              </div>

              <Link to="/casestudy/ancestry-bonsai/" className="" >
              <div className="row p-20">
                <div className="col-md-8 col-sm-8 col-xs-12 last-md last-sm">
                  <h2 className="heading"><strong><span className="numbers">2</span>Updating and Maintaining Ancestry’s Interface</strong></h2>
                  <p>Ancestry has been an online company since 1996. Over the past three years, I led a continuous effort to maintain and update the design.</p>
                  <p>This effort included the development of a pattern library, improvements in accessibility for disabled users, and additional design support for new products.</p>
                  <p><img alt="" src={ResumeIcon} style={{"width":".75em", "verticalAlign":"middle","marginRight":".25em"}}/> <strong>Read This Case Study</strong></p>
                </div>

                <div className="col-md-4 col-sm-4 col-xs-12 p-20">
                  <ScrollAnimation animateIn="fadeInLeft" duration={1}>
                    <img alt="" src={Ancestry2} style={{"objectFit":"cover","width":"100%"}}/>
                  </ScrollAnimation>
                </div>


              </div>
              </Link>
              <Link to="/casestudy/ancestry-flowmapper/" className="" >
              <div className="row p-20">

                <div className="feature col-sm-8 col-xs-12 p-20">
                  <h2 className="heading"><strong><span className="numbers">3</span>Detailed Mapping of the Ancestry Customer Journey</strong></h2>
                  <p>Ancestry is a complex product that millions of people use daily in very different ways. The digital journey map helped build a common understanding of how the product worked. It helped us identify areas for improvement, and helped to educate new employees on how the whole system functions.</p>

                  <p><img alt="" src={ResumeIcon} style={{"width":".75em", "verticalAlign":"middle","marginRight":".25em"}}/> <strong>Read This Case Study</strong></p>
                </div>
                <div className="feature col-sm-4 col-xs-12 p-20">
                  <ScrollAnimation animateIn="fadeInRight" duration={1}>
                    <img alt="" src={Ancestry3} style={{"objectFit":"cover","width":"100%"}}/>
                  </ScrollAnimation>
                </div>
              </div>
              </Link>
              <Link to="/casestudy/ancestry-new-directions/" className="" >
              <div className="row p-20">
                <div className="feature col-sm-8 col-xs-12 p-20 last-sm">
                  <h2 className="heading"><strong><span className="numbers">4</span>New Directions for Ancestry</strong></h2>
                  <p>Throughout my employment at Ancestry, I lead and participated in the generation of hundreds of concepts for new products. Many ideas remain only as sketches, and many have made their way into the product in one form or another. I am as comfortable with markers and paper as I am on the computer.</p>
                  <p><img alt="" src={ResumeIcon} style={{"width":".75em", "verticalAlign":"middle","marginRight":".25em"}}/> <strong>Read This Case Study</strong></p>
                </div>
                <div className="feature col-sm-4 col-xs-12 p-20">
                  <ScrollAnimation animateIn="fadeInLeft" duration={1}>
                    <img alt="" src={Ancestry4} style={{"objectFit":"cover","width":"100%"}}/>
                  </ScrollAnimation>
                </div>
              </div>
              </Link>
            </div>
          </section>

          <section className="mt-100 testimonial">
            <div className="testimonial-wrapper">
            <ScrollAnimation animateIn="fadeIn" duration={.5}>
              <div className="testimonial">
                <p>“You will see lots of articles about hiring designers that say stuff like, “Full-stack designers don’t exist! Quit looking for unicorns! Respect specialization!” That’s because the authors haven’t worked with John. From visual design, customer research, solving intense interaction design problems, reworking challenging information architectures, writing, all the way to coding up design tools that give the team superpowers. He does it all and does it all well.”</p>
              <p>“If I didn’t love the guy so much, I would hate him out of jealousy.”</p>
              </div>

              <div className="testimonial-caption">
              <div className="avatar"><img alt="" src={Penrod}/></div>
              <p className="testimonial-caption"><strong>Josh Penrod</strong><br/>Chief Product Officer at Podium<br/>Formerly VP of UX at Ancestry</p>
              </div>

            </ScrollAnimation>
            </div>
          </section>


          <section className="contentSection mt-60">

            <div className="row">
            <div className="col-xs-12 col-sm-9">
              <h1>Leadership & Management</h1>
              <p>The most rewarding benefits of my career have been watching people I’ve had the opportunity to “manage” grow into amazing designers and leaders.</p>
              <p>
              Leadership is about building trust, and enabling teams to do their best work.
              </p>
              <p>I believe that the best leaders don't put themselves on a pedestal. They treat everyone with respect and help boost great ideas no matter where they come from.</p>
            </div>

            </div>

          </section>

          <section className="contentSection">
            <div  className="mt-40" >
              <div className="row">
                <div className="col-xs-12 col-sm-9">
                  <div>
                    <h1>Writing on Creativity & User Experience</h1>
                    <p>Designing things is a complicated job, and we are all still trying to make sense of it. I've written some perspectives I've had about design and creativity, and selected a few so you can get to know how I think and what is important to me.
                    </p>
                  </div>
                  <div className="col-md-6"></div>
                </div>
              </div>
              <div className="row">
              {posts
                .map(({ node: post, index }) => (
                  <Link to={post.fields.slug} key={post.id} className="col-xs-12 col-sm-6 col-md-3">
                    <div className="postcard" >
                      <div className="cardthumb">
                        <Img alt="" fluid={post.frontmatter.thumb.childImageSharp.fluid} />
                      </div>
                      <div className="cardtitle">
                        <h3 className="no-margin">
                          {post.frontmatter.title}
                        </h3>
                        <p className="opacity-half description">
                          {post.frontmatter.description}
                        </p>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
              </div>
            </section>


            <section className="contentSection tripSection mt-100">
              <div className="row">
                <div className="col-xs-12 col-sm-9">
                  <h1>Outside of the Workplace</h1>
                  <p>All work and no play makes me a dull person. I like to play as hard as I work. I love exploring the world and visiting beautiful places. I’m still trying to work out how I can make my job duties exclusive to exploring the great outdoors but till then, I'm working on my weekend warrior game. My most recent trip was to Austin, Texas, where I fell in love with this city again.</p>
                </div>
              </div>

              <div className="row tripList">
              {trips
                .map(({ node: trip }) => (
                <Link
                  to={trip.fields.slug}
                  key={trip.id}
                  className="col-xs-6 col-sm-4 col-md-3"
                >
                <div className="trip">
                  <h2 className="no-margin">
                    {trip.frontmatter.title}
                  </h2>
                  <Img fluid={trip.frontmatter.thumb.childImageSharp.fluid}/>
                </div>
                </Link>
              ))}
              </div>
            </section>

            <section className="contentSection artworkSection mt-100">
              <div className="row">
                <div className="col-xs-12 col-md-9">
                  <h1>There's more to tell</h1>
                  <p>I hope this page helps you to get to know me a little.</p>
                  <p>I know how to build great teams and make great software, and would love to talk with you more in person about how I could be a part of Procore.</p>
                  <p>I can be reached anytime by email (john.dilworth@gmail.com) or on my mobile phone (801.703.3905). I'm looking forward to talking with you soon.</p>

                </div>
              </div>
            </section>





        </article>
        </div>
      </BodyClassName>
      </PageTransition>
      </Layout>
    )
  }
}

ProCorePage.propTypes = {
  data: PropTypes.shape({
    articles: PropTypes.shape({
        edges: PropTypes.array,
    }),
    trips: PropTypes.shape({
        edges: PropTypes.array,
    }),
    artwork: PropTypes.shape({
        edges: PropTypes.array,
    }),
  }),
}

export const ProCoreQuery = graphql`
  query ProCoreQuery {
    articles:allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: "ML" }, published: {ne: false} } }
      limit: 4
    ) {
      edges {
        node {
          excerpt(pruneLength: 100)
          timeToRead
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            description
            date(formatString: "MMMM DD, YYYY")
            path
            thumb{
              childImageSharp{
                fluid(maxWidth: 500){
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    trips:allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date, frontmatter___title]},
      filter: { frontmatter: { templateKey: { eq: "trip" } }},
      limit: 8
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            thumb{
              childImageSharp{
                fluid(maxWidth: 500){
                  ...GatsbyImageSharpFluid
                }
              }
            }
            templateKey
            date(formatString: "MMMM DD, YYYY")
            path
          }
        }
      }
    }
  }
  `
